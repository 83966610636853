#header-wrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
  background-color: white;
  height: 100%;
  align-items: center;
  padding: 0.4rem 1rem;
  justify-content: space-between;
  height: 100%;

  .header-menu {
    border-bottom: 0px solid #f0f0f0;
    .header-menu-item {
      border-bottom: 0px solid #1890ff;
    }
  }
}

#header-logo {
  position: relative;
  height: 30px;
  width: auto;
}

@primary-color: #3f51b5;@border-radius-base: 6px;@outline-color: #3f51b5;@outline-width: 0.05rem;@outline-fade: 80%;@table-padding-vertical: 0;@table-padding-horizontal: 12px;@button-font-weight: 600;@font-family: 'Poppins', sans-serif;@height-base: 36px;@btn-shadow: none;@btn-primary-shadow: none;@btn-text-shadow: none;@typography-title-font-weight: 500;