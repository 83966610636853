.breadcrumb-subroute {
  color: #a7a7a7;

  &.link {
    transition: color 0.5 ease-in-out;

    &:hover {
      color: #1890ff;
    }
  }
}

.no-select {
  user-select: none;
}

@primary-color: #3f51b5;@border-radius-base: 6px;@outline-color: #3f51b5;@outline-width: 0.05rem;@outline-fade: 80%;@table-padding-vertical: 0;@table-padding-horizontal: 12px;@button-font-weight: 600;@font-family: 'Poppins', sans-serif;@height-base: 36px;@btn-shadow: none;@btn-primary-shadow: none;@btn-text-shadow: none;@typography-title-font-weight: 500;