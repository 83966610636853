#wrapper {
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: linear-gradient(-45deg, #bb6ba8, #833571, #47c4f1, #27c179);
  background-size: 400% 400%;
  animation: gradient 15s ease infinite;
  padding: 20%;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

#formContent {
  -webkit-border-radius: 10px 10px 10px 10px;
  border-radius: 10px 10px 10px 10px;
  background: #fff;
  padding: 15px;

  -webkit-box-shadow: 0 30px 60px 0 rgba(0, 0, 0, 0.3);
  box-shadow: 0 30px 60px 0 rgba(0, 0, 0, 0.3);
  text-align: center;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

#topLogo {
  width: 50%;
  margin: 20px;
}

#buttonsWraper {
  display: flex;
  flex-direction: column;
}

@primary-color: #3f51b5;@border-radius-base: 6px;@outline-color: #3f51b5;@outline-width: 0.05rem;@outline-fade: 80%;@table-padding-vertical: 0;@table-padding-horizontal: 12px;@button-font-weight: 600;@font-family: 'Poppins', sans-serif;@height-base: 36px;@btn-shadow: none;@btn-primary-shadow: none;@btn-text-shadow: none;@typography-title-font-weight: 500;