@import 'antd/dist/antd.less';
@import './fonts.less';
@import './helpers.less';
@import './variables.less';
@import './breakpoints.less';

@font-family: 'Poppins', sans-serif;
@border-radius: 10px;
/*-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
      'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif*/

html,
body,
#root,
#app {
  height: 100%;
}

html,
body,
input,
button,
h1,
h2,
h3,
p {
  .default-font {
    font-family: @font-family;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
}

body {
  margin: 0;
  font-family: @font-family;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

h4.ant-typography,
.ant-typography h4 {
  font-weight: 500;
}

.reactrouter-link {
  text-decoration: none;
  padding: 0;
  margin: 0;
  color: black;

  &:focus,
  &:hover,
  &:visited,
  &:link,
  &:active {
    text-decoration: none;
  }
}

.custom-scrollbar {
  scrollbar-width: thin;
  scrollbar-color: @gray-6;
  &::-webkit-scrollbar {
    width: 12px;
    scroll-padding: 50px 0 0 50px;
  }
  &::-webkit-scrollbar-track {
    padding-top: 2px;
    padding-bottom: 2px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 8px;
    border: 3px solid #fafafa;
    background: @gray-6;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: @gray-7;
  }
}

//Added default height in rows to have the same height when rows has action button or not
.ant-table-thead > tr > th,
.ant-table-tbody > tr > td,
.ant-table tfoot > tr > th,
.ant-table tfoot > tr > td {
  min-height: 60px;
  height: 60px;
}

@primary-color: #3f51b5;@border-radius-base: 6px;@outline-color: #3f51b5;@outline-width: 0.05rem;@outline-fade: 80%;@table-padding-vertical: 0;@table-padding-horizontal: 12px;@button-font-weight: 600;@font-family: 'Poppins', sans-serif;@height-base: 36px;@btn-shadow: none;@btn-primary-shadow: none;@btn-text-shadow: none;@typography-title-font-weight: 500;