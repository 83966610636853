.overlay-container {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  padding: 10px;
  background-color: rgba(white, 0.8);
  z-index: 4;
  &.center {
    align-items: center;
    justify-content: center;
  }
}

@primary-color: #3f51b5;@border-radius-base: 6px;@outline-color: #3f51b5;@outline-width: 0.05rem;@outline-fade: 80%;@table-padding-vertical: 0;@table-padding-horizontal: 12px;@button-font-weight: 600;@font-family: 'Poppins', sans-serif;@height-base: 36px;@btn-shadow: none;@btn-primary-shadow: none;@btn-text-shadow: none;@typography-title-font-weight: 500;