@import '../../assets/styles/variables.less';

.notification {
  padding: 0.6rem 0.8rem;
  line-height: 1;
  font-size: 0.8rem;
  min-height: 110px;
  &:hover {
    background-color: @gray-3;
  }
  .notification_actor {
    font-weight: 700;
    margin-right: 0.3rem;
    margin-bottom: 10px;
  }
  .notification_message {
    font-weight: 400;
    margin-right: 0.3rem;
  }
  .notification_requestCode {
    font-weight: 400;
    margin-bottom: 0.6rem;
  }
  .notification_date {
    color: gray;
  }
  .notification_main_message {
    line-height: 1.2rem;
  }
}

.custom-button {
  border: none;
  padding: 0px;
  height: 24px;
  min-width: 24px;
  border-radius: 50%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #00000017;
  pointer-events: all;

  cursor: pointer;

  &:focus {
    box-shadow: rgb(38 132 255) 0px 0px 0px 2px;
  }
}

.indicator-area {
  background-color: rgb(0, 82, 204);
  height: 8px;
  width: 8px;
  border-radius: 50%;
}

@primary-color: #3f51b5;@border-radius-base: 6px;@outline-color: #3f51b5;@outline-width: 0.05rem;@outline-fade: 80%;@table-padding-vertical: 0;@table-padding-horizontal: 12px;@button-font-weight: 600;@font-family: 'Poppins', sans-serif;@height-base: 36px;@btn-shadow: none;@btn-primary-shadow: none;@btn-text-shadow: none;@typography-title-font-weight: 500;