@import '../../assets/styles/variables.less';

.empty-list-wrapper {
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  p.empty-list-icon {
    font-size: 40px;
    color: rgba(#1890ff, 0.75);
    margin-bottom: 10px;
  }
  p.empty-list-text {
    font-size: 16px;
    margin-bottom: 5px;
    font-weight: 600;
  }
  p.empty-list-hint {
    font-size: 13px;
  }
}

label.show-only-unread-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  span {
    margin-right: 0.3rem;
  }
}

button.btn-mark-all-asread {
  position: absolute;
  right: 0;
  top: -46px;
}

@primary-color: #3f51b5;@border-radius-base: 6px;@outline-color: #3f51b5;@outline-width: 0.05rem;@outline-fade: 80%;@table-padding-vertical: 0;@table-padding-horizontal: 12px;@button-font-weight: 600;@font-family: 'Poppins', sans-serif;@height-base: 36px;@btn-shadow: none;@btn-primary-shadow: none;@btn-text-shadow: none;@typography-title-font-weight: 500;