.flex {
  display: flex;
  padding: 0;

  &.column {
    flex-direction: column;
  }

  &.row {
    flex-direction: row;
  }

  &.center {
    justify-content: center;
    align-items: center;
  }

  &.justify {
    justify-content: center;
  }

  &.align {
    align-items: center;
  }
}

.full {
  position: relative;

  &.width {
    width: 100%;
  }
  &.height {
    height: 100%;
  }
  &.screen {
    height: 100%;
    width: 100%;
  }
}

.no {
  &-padding {
    padding: 0;
  }
  &-margin {
    margin: 0;
  }
}
