@import '../assets/styles/variables.less';

#app {
  position: absolute;
  width: 100%;
  display: grid;
  background-color: @gray-2;
  grid-template-rows: 1fr 12fr;
  grid-template-columns: auto 6fr;
  grid-template-areas:
    'header header'
    'sider content';
}

#header {
  grid-area: header;
  background-color: @gray-1;
  //border-bottom: 0.05rem solid @gray-5;
  box-shadow: 0 2px 8px #f0f1f2;
  z-index: 1;
}

#sider {
  grid-area: sider;
  z-index: 0;
  max-height: 100%;
  overflow: auto;
  //border-right: 0.5px solid @gray-5;
  background-color: #ffffff;
  transition: all 0.7s;
  //padding-top: 20px;
}

.sider {
  &-expanded {
    width: 15rem;
  }
  &-collapsed {
    width: 5rem;
  }
}

.ant-menu-inline-collapsed {
  &-tooltip {
    div {
      display: none;
    }
  }
}

#breadcrumb {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: @gray-1;
  //box-shadow: 2px 3px 15px rgba(0, 0, 0, 0.15);
  padding: 0 1rem;
  height: 50px;
}

#content {
  grid-area: content;
  background-color: @gray-2;
  overflow: auto;
  &::-webkit-scrollbar {
    width: 12px;
    scroll-padding: 50px 0 0 50px;
  }
  &::-webkit-scrollbar-track {
    padding-top: 2px;
    padding-bottom: 2px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 8px;
    border: 3px solid #fafafa;
    background: @gray-6;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: @gray-7;
  }
}

.scrollable-content {
  overflow: auto;
  scrollbar-width: thin;
  scrollbar-color: @gray-6;
  &.has-padding {
    padding: 20px;
  }
  &::-webkit-scrollbar {
    width: 8px;
    scroll-padding: 50px 0 0 50px;
  }
  &::-webkit-scrollbar-track {
    padding-top: 2px;
    padding-bottom: 2px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background: @gray-6;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: @gray-7;
  }
}

.ant-tag.lg {
  padding: 5px 8px;
  font-size: 0.85rem;
}

.scrollable-table {
  // background-color: grey;
  width: 100%;
  display: flex;
  flex-grow: 1;
  overflow: auto;
  max-height: 100%;
  &::-webkit-scrollbar {
    width: 12px;
    scroll-padding: 50px 0 0 50px;
  }
  &::-webkit-scrollbar-track {
    padding-top: 2px;
    padding-bottom: 2px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 8px;
    border: 3px solid #fafafa;
    background: @gray-6;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: @gray-7;
  }
}

.inner-wrapper-content {
  padding: 20px;
  &.is-form-page {
    padding: 24px;
    //min-height: calc(100% - 60px);
  }
  .cardPadding {
    padding: 16px 24px;
    .mb {
      margin-bottom: 2rem;
    }
    .mt {
      margin-top: 2rem;
    }
    .mr {
      margin-right: 0.9rem;
    }
    h4 {
      font-size: 1.3rem;
    }
  }
}

#content-spinner-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ant-btn {
  font-weight: 600;
}

/******HELPERS***********/
.p-relative {
  position: relative;
}

.no-padding {
  &.ant-popover-inner-content {
    padding: 0;
  }
}

.ant-popover-inner-content {
  padding: 5px !important;
}

.crud {
  &-wrapper {
    width: 100%;
    height: calc(100% - 50px);
    display: flex;
    flex-direction: column;
  }

  &-filters {
    flex-grow: 1;
  }

  &-table-wrapper {
    flex-grow: 100;
    width: 100%;
    overflow: auto;
    overflow-y: scroll;
    border: 1px solid @gray-4;
    // box-shadow: 0px 5px 5px rgba(0,0,0,0.2);
  }
}

.ant-notification-notice {
  padding: 5px !important;
}

@primary-color: #3f51b5;@border-radius-base: 6px;@outline-color: #3f51b5;@outline-width: 0.05rem;@outline-fade: 80%;@table-padding-vertical: 0;@table-padding-horizontal: 12px;@button-font-weight: 600;@font-family: 'Poppins', sans-serif;@height-base: 36px;@btn-shadow: none;@btn-primary-shadow: none;@btn-text-shadow: none;@typography-title-font-weight: 500;